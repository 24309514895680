
import { Component, Prop, Vue } from 'vue-property-decorator';
import VHeadlineAction from '../shared/VHeadlineAction.vue';
import { InformationActionType } from '../../enums/InformationActionType';

interface Action {
  type: InformationActionType;
  name: string;
}
@Component({
  components: { VHeadlineAction },
})
export default class ActionForm extends Vue {
  @Prop({ type: Array, required: true }) public items!: Action[];

  public actions: Action[] = [];

  get actionTypes() {
    return [
      {
        value: InformationActionType.Primary,
        text: this.$t('information.actionTypes.primary'),
      },
      {
        value: InformationActionType.Secondary,
        text: this.$t('information.actionTypes.secondary'),
      },
      {
        value: InformationActionType.Cancel,
        text: this.$t('information.actionTypes.cancel'),
      },
    ];
  }

  public mounted(): void {
    this.actions = [...this.items];
  }

  public getData() {
    return this.actions;
  }

  public addAction() {
    this.actions.push({
      type: InformationActionType.Primary,
      name: '',
    });
  }

  public removeAction(action: Action) {
    this.actions.splice(this.actions.indexOf(action), 1);
  }
}
