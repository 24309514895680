
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import EditTableLoader from '@/components/table/EditTableLoader.vue';
import Notification from '@/mixins/Notification';
import EditInformationLoader from '@/components/information/EditInformationLoader.vue';
import InformationForm from '@/components/information/InformationForm.vue';
import { Information } from '@/interfaces/models/Information';
import StackedForm from '@/mixins/StackedForm';

const information = namespace('information');

@Component({
  components: { InformationForm, EditInformationLoader, EditTableLoader, VWrapper },
})
export default class EditInformation extends mixins(Editable, StackedForm, Notification) {
  @information.Action('store') public store!: any;
  @information.Action('update') public update!: any;
  @information.Action('show') public show!: any;
  @information.Action('uploadPhoto') public uploadPhoto!: any;
  @information.State('active') public active!: Information;

  public $refs!: {
    form: InstanceType<typeof InformationForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get information() {
    if (this.editing && this.active) {
      return { ...this.active, range: { from: this.active.from, to: this.active.to } };
    }

    return null;
  }

  get title() {
    return this.editing ? 'information.edit' : 'information.create';
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('information');
      await this.show({ id: this.id });
      this.$stopLoading('information');
    }
    if (this.editing && !this.active) {
      this.$router.push({ name: 'information.index' });
    }
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        this.$startLoading('information.save');
        const data: Information = { ...this.$refs.form.getData() };
        if (this.editing) {
          await this.update({ ...data, id: this.id });
        } else {
          await this.store({ ...this.$refs.form.getData() });
        }

        if (!this.active) {
          this.$stopLoading('information.save');
          this.$router.push({ name: 'information.index' });
          return;
        }

        let formData: FormData;
        if (data.image && data.image instanceof File) {
          formData = new FormData();
          formData.append('asset', data.image);
          await this.uploadPhoto({ id: this.id, image: formData });
        }
        this.$stopLoading('information.save');
        this.$router.push({ name: 'information.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
