
import { Component } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { InformationActionType } from '@/enums/InformationActionType';
import ActionForm from '@/components/information/ActionForm.vue';

@Component({
  components: { VFormBuilder, ActionForm },
})
export default class InformationForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
    action: InstanceType<typeof ActionForm> & { getData: () => Array<{ type: InformationActionType; name: string }> };
  };

  get items() {
    return [
      { name: 'image', type: InputType.File, label: 'information.form.image', default: null, accept: 'image/*' },
      {
        name: 'title',
        type: InputType.Language,
        useTextArea: true,
        label: 'information.form.title',
        rules: 'required',
      },
      {
        name: 'description',
        type: InputType.Language,
        useTextArea: true,
        label: 'information.form.description',
        rules: 'required',
      },
      { name: 'range', type: InputType.DateRange, rules: 'required' },
      { name: 'url', type: InputType.Text, label: 'information.form.url' },
      // TODO: actions
    ];
  }

  public getData() {
    return { ...this.$refs.form.form, ...this.$refs.form.form.range, actions: this.$refs.action.getData() };
  }
}
